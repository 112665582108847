import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import ContactForm from "../components/contact"
import {Container,Row,Col} from 'react-bootstrap'
import SEO from "../components/seo"


const mobileDesktop =()=>{
    return(
    <Layout>
        <SEO title="Embedded Software" />
        <div className="homeAuto">
            <Image alt="home automation" filename="embed.jpg"  />
                <div className="embImage">
                    <h1 style={{fontWeight:"bold"}} className="embhead">Your Vision.Powered by embedded software.</h1>
                </div>
        </div>
        <Container>
            <h3 className="embh3">Enabling business and technological innovation</h3>
            <Row>
                <Col sm={12} lg={4}>
                    <Image alt="cloud" filename="soft3.png" />
                    <div className="embHeader">Embedded software development</div> 
                    <div className="crmPara">We offer a wide range of embedded software development services to turn your design into a fully functional system.</div>
                </Col>

                <Col sm={12} lg={4}>
                    <Image alt="cloud" filename="soft2.png" />
                    <div className="embHeader">Embedded systems development</div> 
                    <div className="crmPara">Working with our hardware design partners, we act as a single point of supply for complete embedded system development.</div>
                </Col>

                <Col sm={12} lg={4}>
                    <Image alt="cloud" filename="soft1.png" />
                    <div className="embHeader">Device drivers</div> 
                    <div className="crmPara">Using Kernel level programming, we develop high tech device drivers for embedded solutions.</div>
                 </Col>
                
            </Row>
            <hr className="embhr"/>
                <Row className="mechaRow">
                    <Col sm={12} lg={6} className="empRow">
                        <p>Backed by our strong domain knowledge in embedded software and automation methodology, Trace Width Technology Solutions offers services for design, development, and porting of BSP, device drivers and complete platform software customization. The enhancements that are offered for embedded systems are based on major operating systems and desktop environments. Trace Width has delivered top notch driver software for a multitude of market sectors.</p>
                    </Col>
                    <Col sm={12} lg={6} ><Image alt="mechanical" filename="embed-software.png" /></Col>
                    
                </Row>
                <Row className="mechaRow">
                    <Col sm={12} lg={6} ><Image alt="mechanical" filename="emb-software.png" /></Col>
                    <Col sm={12} lg={6} className="empRow">
                        <p>Trace Width technologies has specialization in software system design for MIPS, PowerPC, ARM, x86 based processors, and System-on-Chips. We exercise our in-depth knowledge associated with the development of software/firmware for digital signal processors (DSP), microprocessors, and micro controllers. Our vast experience in embedded solutions enables us to provide high quality of services pertaining to the development of both software as well as hardware pertaining to embedded systems.</p>
                    </Col>
                </Row>
            <hr className="embhr"/>
        </Container>
       
        <h3 className="embh3">Weaving Software & Hardware together</h3>
        <p className="embPara">Like no other software service vendor on the market, Trace Width is perfectly shaped for any Embedded software development.</p>

            
        <div className="headerForm"> 
            <br/>
            <div className="homeCardrow5">Let us know your project needs and let’s make your vision a reality.</div>
            <ContactForm  />
        </div>
          
    </Layout>
);

}

export default mobileDesktop;

